<template>
  <footer class="footer">
    <div class="container footer__container">
      <div class="footer__column footer__address">
        <div class="footer__address-item">
          <h4 class="footer__title">
            Registration
          </h4>
          <p class="footer__text">
            DexNet Information Technology CO <br>
            Register №: 993835 <br>
            License: 1207881
          </p>
        </div>
        <address class="footer__address-item">
          <h4 class="footer__title">Address</h4>
          <p class="footer__text">
            Conrad Business Tower, Office 1008 <br>
            Sheikh Zayed Road, Dubai, UAE <br>
            PO Box 30208
            <!-- P.O. Box 115143 -->
          </p>
        </address>
      </div>

      <nav class="footer__column footer__nav">
        <div class="footer__nav-col">
          <h4 class="footer__title">
            Certificates
          </h4>
          <ul class="footer__nav-list">
            <li><a href="/certificates/FCC_ID_2BESI-DEXNET_DSS_Grant.pdf" target="_blank" class="footer__nav-link">DSS</a></li>
            <li><a href="/certificates/LCSA01094040E001_CE-RED.pdf" target="_blank" class="footer__nav-link">CE RED</a></li>
            <li><a href="/certificates/FCC_ID_2BESI-DEXNET_DTS_Grant.pdf" target="_blank" class="footer__nav-link">DTS</a></li>
            <li><a href="/certificates/FCC_ID_2BESI-DEXNET_DXX_Grant.pdf" target="_blank" class="footer__nav-link">DXX</a></li>
            <li><a href="/certificates/FCC_ID_2BESI-DEXNET_NII_Grant.pdf" target="_blank" class="footer__nav-link">NII</a></li>
            <li><a href="/certificates/LCSD01094003R-Certificate.pdf" target="_blank" class="footer__nav-link">RohS</a></li>
            <li><a href="/certificates/DEXNET_INFORMATION_TECHNOLOGY_CO._ISO_9001_2024_2025.pdf" target="_blank" class="footer__nav-link">ISO</a></li>
          </ul>
        </div>

        <div class="footer__nav-col">
          <h4 class="footer__title">
            Contacts
          </h4>
          <ul class="footer__nav-list">
            <li>
              <a
                href="https://t.me/dexnet"
                target="_blank"
                class="footer__nav-link"
              >
                Telegram
              </a>
            </li>
            <li>
              <a
                href="https://x.com/DexNet_one"
                target="_blank"
                class="footer__nav-link"
              >
                x.com
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/dexnetone/"
                target="_blank"
                class="footer__nav-link"
              >
                Linkedln
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/dexnet.one/"
                class="footer__nav-link"
              >
                Instagram
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/profile.php?id=100094044572486"
                target="_blank"
                class="footer__nav-link"
              >
                Facebook
              </a>
            </li>
            <li>
              <a
                href="mailto:info@dexnet.one"
                target="_blank"
                class="footer__nav-link"
              >
                Email
              </a>
            </li>
          </ul>
        </div>

        <div class="footer__nav-col">
          <h4 class="footer__title">
            Legal
          </h4>
          <ul class="footer__nav-list">
            <li>
              <a
                class="footer__nav-link"
                :href="`/docs/whitepaper-${$root.$i18n.locale}.pdf`"
              >
                {{ $t('lnk_whitepaper') }}
              </a>
            </li>
            <li>
              <a
                href="/docs/gdpr.pdf"
                class="footer__nav-link"
              >
                {{ $t('lnk_gdpr') }}
              </a>
            </li>
            <li>
              <a
                href="/docs/DEXNET_audit_report.pdf"
                class="footer__nav-link"
              >
                {{ $t('lnk_audit') }}
              </a>
            </li>
            <li>
              <a
                href="/docs/privacy_policy.html"
                class="footer__nav-link"
              >
                {{ $t('lnk_privacy_policy') }}
              </a>
            </li>
            <li>
              <a
                href="/docs/public_offer.pdf"
                class="footer__nav-link"
              >
                {{ $t('lnk_public_offer') }}
              </a>
            </li>
            <li>
              <a
                href="/docs/cookie_policy.pdf"
                class="footer__nav-link"
              >
                {{ $t('lnk_cookies') }}
              </a>
            </li>
            <li>
              <a
                href="/docs/user_agreement.pdf"
                class="footer__nav-link"
              >
                {{ $t('lnk_user_agreement') }}
              </a>
            </li>
            <li>
              <a
                class="footer__nav-link"
                href="/manuals/"
              >
                {{ $t('lnk_manuals') }}
              </a>
            </li>
          </ul>
        </div>
      </nav>

      <div class="footer__desc">
        <p class="footer__desc-text">
          {{ $t('footer_1') }}
<!--          Technology and company under the patronage <br>-->
<!--          of Sheikh Majid Rashid Al Mualla-->
        </p>

        <p class="footer__desc-text">
          © 2023-2024 DEXNET.
          {{ $t('all_rights_reserved') }}
        </p>
      </div>
    </div>

    <div class="footer__bottom">
      <!-- <div class="footer-bg-text">
        DEXNET
      </div> -->
      <img
        src="@/assets/images/footer-bottom.png"
        class="footer__bottom-img"
        alt="DexNet"
      >
    </div>
  </footer>
</template>
<script>
export default {
  
}
</script>

<!-- <script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { RouterLink } from 'vue-router';

@Component({})
export default class LndFooter extends Vue {
  public mounted() {
    console.debug('%c lnd/Footer.vue mounted', 'color:magenta;font-size:24px;')
  }
}
</script> -->
